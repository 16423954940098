import React, { useRef } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import { transitionLink } from "../const/attr"

import {
  checkForSlug,
  onTheFirstFloor,
  makeNiceUrl,
  getOpeningHours,
} from "../functions/functions"
import { labels, localeUrls, badgesLocale } from "../langs/langs"
import { sliderSettings1 } from "../const/attr"
import { flagsIcons } from "../const/icons"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { rSVG, lSVG } from "../components/ArrowLink"
import IsometricMapSVG0 from "../components/IsometricMapSVG_0"
import IsometricMapSVG1 from "../components/IsometricMapSVG_1"
import StoresSlider from "../components/StoresSlider"
import PartnersSlider from "../components/PartnersSlider"

import wavy from "../images/wavy4.svg"
import clockI from "../images/clock-black.svg"
import mailI from "../images/mail.svg"
import phoneI from "../images/phone.svg"
import fbIcon from "../images/fb.svg"
import inIcon from "../images/ig.svg"
import rightIcon from "../images/arrowR.svg"
import leftIcon from "../images/arrowL.svg"

const StoreDetailTemplate = ({
  location,
  data: { previous, next, post, interests },
  pageContext: { localeID },
}) => {
  const settingsModal = {
    ...sliderSettings1,
    slidesToShow: 1,
    nextArrow: <img src={rightIcon} />,
    prevArrow: <img src={leftIcon} />,
    dots: false,
  }

  const slider = useRef()

  const urlTranslations = {
    en_US:
      localeUrls["en_US"]["STORES"] +
      "/" +
      (localeID === "en_US"
        ? post.slug
        : !!post.translated[0]?.slug
        ? post.translated[0].slug
        : ""),
    sk_SK:
      localeUrls["sk_SK"]["STORES"] +
      "/" +
      (localeID === "sk_SK"
        ? post.slug
        : !!post.translated[0]?.slug
        ? post.translated[0].slug
        : ""),
  }

  return (
    <Layout
      location={location}
      localeID={localeID}
      translatedSlug={urlTranslations}
    >
      <Seo title={post.title} description={post.excerpt} />
      <div className="featuredImgCon">
        <GatsbyImage
          image={
            post?.featuredImage?.node?.localFile?.childrenImageSharp[0]
              ?.gatsbyImageData
          }
          alt={post?.featuredImage?.node?.altText ?? "Ilustrácia"}
        />
        <img src={wavy} />
      </div>
      <div>
        <div className="content storeHead">
          <div>
            <div className="center l">
              {!!previous && (
                <AniLink
                  {...transitionLink}
                  to={localeUrls[localeID]["STORES"] + "/" + previous.slug}
                  className="center aArow left"
                >
                  {lSVG("#000")}
                  <div className="logoCirCon aA">
                    <GatsbyImage
                      image={
                        previous?.acfStoresData?.logosquare?.localFile
                          ?.childrenImageSharp[0]?.gatsbyImageData
                      }
                      alt={
                        previous?.acfStoresData?.logosquare?.altText ?? "logo"
                      }
                    />
                  </div>
                </AniLink>
              )}
            </div>
            <div>
              <div className="logoCirCon main">
                <GatsbyImage
                  image={
                    post?.acfStoresData?.logosquare?.localFile
                      ?.childrenImageSharp[0]?.gatsbyImageData
                  }
                  alt={post?.acfStoresData?.logosquare?.altText ?? "logo"}
                />
              </div>
              <h1>{post.title}</h1>
              {!!post.tags.nodes.length && (
                <div className="tagsCon">
                  {!!post.tags.nodes.length &&
                    post.tags.nodes.map((tag, indexT) => {
                      return (
                        <div key={indexT} className="tag">
                          {!!flagsIcons?.[tag.slug] ? (
                            <img src={flagsIcons[tag.slug]} />
                          ) : (
                            ""
                          )}
                          <span>{tag.name}</span>
                        </div>
                      )
                    })}
                </div>
              )}
              <div className="badgeCon">
                {checkForSlug(
                  post.categories.nodes,
                  localeUrls[localeID]["STORES_F"]
                ) && (
                  <AnchorLink
                    to={
                      localeUrls[localeID]["STORES"] +
                      "#" +
                      localeUrls[localeID]["STORES_F"]
                    }
                  >
                    <img src={badgesLocale[localeID]["mark"]} />
                  </AnchorLink>
                )}
                {checkForSlug(
                  post.categories.nodes,
                  localeUrls[localeID]["STORES_E"]
                ) && (
                  <AnchorLink
                    to={
                      localeUrls[localeID]["STORES"] +
                      "#" +
                      localeUrls[localeID]["STORES_E"]
                    }
                  >
                    <img src={badgesLocale[localeID]["cuis"]} />
                  </AnchorLink>
                )}
                {checkForSlug(
                  post.categories.nodes,
                  localeUrls[localeID]["STORES_G"]
                ) && (
                  <AnchorLink
                    to={
                      localeUrls[localeID]["STORES"] +
                      "#" +
                      localeUrls[localeID]["STORES_G"]
                    }
                  >
                    <img src={badgesLocale[localeID]["groc"]} />
                  </AnchorLink>
                )}
                {checkForSlug(
                  post.categories.nodes,
                  localeUrls[localeID]["STORES_C"]
                ) && (
                  <AnchorLink
                    to={
                      localeUrls[localeID]["STORES"] +
                      "#" +
                      localeUrls[localeID]["STORES_C"]
                    }
                  >
                    <img src={badgesLocale[localeID]["bars"]} />
                  </AnchorLink>
                )}
              </div>
            </div>
            <div className="center r">
              {!!next && (
                <AniLink
                  {...transitionLink}
                  to={localeUrls[localeID]["STORES"] + "/" + next.slug}
                  className="center aArow"
                >
                  <div className="logoCirCon aA">
                    <GatsbyImage
                      image={
                        next?.acfStoresData?.logosquare?.localFile
                          ?.childrenImageSharp[0]?.gatsbyImageData
                      }
                      alt={next?.acfStoresData?.logosquare?.altText ?? "logo"}
                    />
                  </div>
                  {rSVG("#000")}
                </AniLink>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="content storeInfo">
          <div>
            <div className="ohCon">
              <h5 className="center l">
                <img src={clockI} />
                {labels[localeID]["OPHOURS"]}
              </h5>
              {post.slug === "my-fish" ? (
                <>
                  <a>
                    <span>{labels[localeID]["MOTHU"]}</span>
                    <br />
                    <span className="oh">
                      {getOpeningHours(
                        post.acfStoresData.position,
                        localeID,
                        1,
                        post.slug
                      )}
                    </span>
                  </a>
                  <a>
                    <span>{labels[localeID]["FRID"]}</span>
                    <br />
                    <span className="oh">
                      {getOpeningHours(
                        post.acfStoresData.position,
                        localeID,
                        5,
                        post.slug
                      )}
                    </span>
                  </a>
                </>
              ) : (
                <a>
                  <span>{labels[localeID]["MOFRI"]}</span>
                  <br />
                  <span className="oh">
                    {getOpeningHours(
                      post.acfStoresData.position,
                      localeID,
                      1,
                      post.slug
                    )}
                  </span>
                </a>
              )}

              <a>
                <span>{labels[localeID]["SATR"]}</span>
                <br />
                <span className="oh">
                  {getOpeningHours(
                    post.acfStoresData.position,
                    localeID,
                    6,
                    post.slug
                  )}
                </span>
              </a>
              <a>
                <span>{labels[localeID]["SUND"]}</span>
                <br />
                <span className="oh">
                  {getOpeningHours(
                    post.acfStoresData.position,
                    localeID,
                    7,
                    post.slug
                  )}
                </span>
              </a>
            </div>
            <div className="info">
              <a
                target="_blank"
                href={post.acfStoresData.urladdress}
                className="g"
              >
                {makeNiceUrl(post.acfStoresData.urladdress)}
              </a>
              <div>
                <a
                  href={"mailto:" + post.acfStoresData.mail}
                  className="center l"
                >
                  <img src={mailI} />
                  {post.acfStoresData.mail}
                </a>
                <a
                  href={"tel:" + post.acfStoresData.phone}
                  className="center l"
                >
                  <img src={phoneI} />
                  {post.acfStoresData.phone}
                </a>
              </div>
              <p className="center l">
                <a href={post.acfStoresData.fburl} target="_blank">
                  <img src={fbIcon} />
                </a>
                <a href={post.acfStoresData.igurl} target="_blank">
                  <img src={inIcon} />
                </a>
              </p>
            </div>
            <div className="center">
              {onTheFirstFloor(post.acfStoresData.position) ? (
                <>
                  <p className="vFloor">{labels[localeID]["FFLOOR"]}</p>
                  <IsometricMapSVG1
                    className="hidden"
                    clicked={post.acfStoresData.position}
                  />
                </>
              ) : (
                <>
                  <p className="vFloor">{labels[localeID]["GFLOOR"]}</p>
                  <IsometricMapSVG0
                    className="hidden"
                    clicked={post.acfStoresData.position}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="content storeCont">
          <div>
            <Slider {...settingsModal} ref={slider}>
              {!!post.acfStoresData?.gimg1 && (
                <div>
                  <GatsbyImage
                    image={
                      post.acfStoresData?.gimg1?.localFile
                        ?.childrenImageSharp[0]?.gatsbyImageData
                    }
                    alt={post.acfStoresData?.gimg1?.altText ?? "Ilustrácia"}
                  />
                </div>
              )}
              {!!post.acfStoresData?.gimg2 && (
                <div>
                  <GatsbyImage
                    image={
                      post.acfStoresData?.gimg2?.localFile
                        ?.childrenImageSharp[0]?.gatsbyImageData
                    }
                    alt={post.acfStoresData?.gimg2?.altText ?? "Ilustrácia"}
                  />
                </div>
              )}
              {!!post.acfStoresData?.gimg3 && (
                <div>
                  <GatsbyImage
                    image={
                      post.acfStoresData?.gimg3?.localFile
                        ?.childrenImageSharp[0]?.gatsbyImageData
                    }
                    alt={post.acfStoresData?.gimg3?.altText ?? "Ilustrácia"}
                  />
                </div>
              )}
              {!!post?.featuredImage?.node?.localFile && (
                <GatsbyImage
                  image={
                    post?.featuredImage?.node?.localFile?.childrenImageSharp[0]
                      ?.gatsbyImageData
                  }
                  alt={post?.featuredImage?.node?.altText ?? "Ilustrácia"}
                />
              )}
            </Slider>
          </div>
          <div>{!!post.content && parse(post.content)}</div>
        </div>
      </div>
      {!!interests.nodes.length && (
        <StoresSlider
          localeID={localeID}
          data={interests}
          title={labels[localeID]["MAYINTE"]}
          showMore={false}
        />
      )}
      <PartnersSlider className="gray" localeID={localeID} />
    </Layout>
  )
}

export default StoreDetailTemplate

export const pageQuery = graphql`
  query StoreDetail(
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $parentCat: String!
    $localeID: String!
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      featuredImage {
        node {
          ...ImgQueryDataBig
        }
      }
      acfStoresData {
        logosquare {
          ...ImgQueryData
        }
        position
        urladdress
        subtitle
        phone
        mail
        gimg1 {
          ...ImgQueryDataMedium
        }
        gimg2 {
          ...ImgQueryDataMedium
        }
        gimg3 {
          ...ImgQueryDataMedium
        }
        fburl
        igurl
      }
      categories {
        nodes {
          slug
        }
      }
      tags {
        nodes {
          name
          description
          slug
        }
      }
      translated {
        slug
      }
      slug
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
      acfStoresData {
        logosquare {
          ...ImgQueryDataSmall
        }
      }
      slug
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
      acfStoresData {
        logosquare {
          ...ImgQueryDataSmall
        }
      }
      slug
    }
    interests: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: $parentCat } } } }
        locale: { locale: { eq: $localeID } }
        id: { ne: $id }
      }
      limit: 6
    ) {
      ...StoreSliderData
    }
  }
`
