import React, { useRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { transitionLink } from "../const/attr"
import { Zoom, Fade } from "react-awesome-reveal"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { sliderSettings } from "../const/attr"
import { labels, localeUrls, badgesLocale } from "../langs/langs"
import {
  checkForSlug,
  onTheFirstFloor,
  getOpeningHours,
} from "../functions/functions"
import { useRevealBySize } from "../functions/hooks"
import { flagsIcons } from "../const/icons"

import ArrowLink from "./ArrowLink"

import clockI from "../images/clock-black.svg"
import wavy from "../images/wavy3.svg"
import crossI from "../images/cross-orange.svg"
import webI from "../images/web.svg"

const StoresSlider = ({
  localeID,
  data,
  title,
  icon,
  farmers = false,
  id = "",
  children,
  tagFilter = "",
  setTagFilter = () => {},
  showMore = "",
}) => {
  const slider = useRef()

  const storeNumber = data.nodes.length
  const stores = data.nodes.filter(item => {
    if (!tagFilter) return true
    if (checkForSlug(item.tags.nodes, tagFilter, true)) return true
    return false
  })

  const emptyMsg = (
    <>
      <h3 className="empty">{labels[localeID]["WEARESORRY"]}</h3>
      <p className="empty">{labels[localeID]["NOSTORES"]}</p>
    </>
  )

  const settings = {
    ...sliderSettings,
    autoplay: false,
    infinite: false,
    arrows: true,
    nextArrow: <div />,
    prevArrow: <div />,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  }

  const handleTagClick = e => {
    const clickedTagFilter = e.currentTarget.getAttribute("data-value")
    if (clickedTagFilter === tagFilter) {
      setTagFilter("")
    } else {
      setTagFilter(e.currentTarget.getAttribute("data-value"))
    }
  }

  const renderTags = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onClick: handleTagClick })
    }
  })

  const [damping, triggerOnce] = useRevealBySize()

  return (
    <div className="infSliderCon" id={id}>
      <div
        className={
          "content infSlider cards storeSlider" + (farmers ? " forFarmes" : "")
        }
      >
        <div className="center">
          <div className="hCon center l s">
            {!!icon && (
              <Zoom>
                <img src={icon} />
              </Zoom>
            )}
            <h3 className="b">{title}</h3>
          </div>
          <div className="fillFlex" />
          {!!showMore && (
            <ArrowLink to={showMore} color="#ED6E47">
              ({storeNumber}) {labels[localeID]["SHOWALL"]}
            </ArrowLink>
          )}
        </div>
        {!!React.Children.count(children) && (
          <div className={"tagsConCon " + (renderTags.length > 6 ? "v" : "l")}>
            <div className="tagsCon mg">
              {!!tagFilter && (
                <span className="center" onClick={() => setTagFilter("")}>
                  <img src={crossI} />
                  {labels[localeID]["CANCELTAG"]}
                </span>
              )}
              <Fade cascade={true} damping={damping} triggerOnce={triggerOnce}>
                {renderTags}
              </Fade>
            </div>
          </div>
        )}
        {!!stores.length ? (
          <Slider {...settings} ref={slider}>
            {!!stores.length &&
              stores.map((item, index) => {
                return farmers ? (
                  <FarmerCard key={index} item={item} localeID={localeID} />
                ) : (
                  <StoreCard key={index} item={item} localeID={localeID} />
                )
              })}
          </Slider>
        ) : (
          emptyMsg
        )}
      </div>
    </div>
  )
}

export const StoreCard = ({ item, localeID }) => {
  const urlLink = localeUrls[localeID]["STORES"] + "/" + item.slug
  return (
    <div className="storeCard">
      <AniLink {...transitionLink} to={urlLink}>
        <div className="imgCon16">
          <GatsbyImage
            image={
              item?.featuredImage?.node?.localFile?.childrenImageSharp[0]
                ?.gatsbyImageData
            }
            alt={item?.featuredImage?.node?.altText ?? "Ilustrácia"}
          />
          <img src={wavy} />
        </div>
      </AniLink>
      <div className="info">
        <AniLink {...transitionLink} to={urlLink}>
          <GatsbyImage
            image={
              item?.acfStoresData?.logosquare?.localFile?.childrenImageSharp[0]
                ?.gatsbyImageData
            }
            alt={item?.acfStoresData?.logosquare?.altText ?? "Logo"}
          />
        </AniLink>
        <h3>
          <AniLink {...transitionLink} to={urlLink}>
            {item.title}
          </AniLink>
        </h3>
        <p className="sub">{item.acfStoresData.subtitle}</p>
        <a
          className="center l g"
          target="_blank"
          href={item.acfStoresData.urladdress}
        >
          <img src={webI} /> web
        </a>
        <p className="center l">
          <img src={clockI} />
          <span>{labels[localeID]["OPENED"]}</span>
          <span className="oh">
            {getOpeningHours(
              item.acfStoresData.position,
              localeID,
              null,
              item.slug
            )}
          </span>
        </p>
        <p className="oh">
          {onTheFirstFloor(item.acfStoresData.position)
            ? labels[localeID]["FFLOOR"]
            : labels[localeID]["GFLOOR"]}
        </p>
        {checkForSlug(
          item.categories.nodes,
          localeUrls[localeID]["STORES_F"]
        ) && <img src={badgesLocale[localeID]["mark"]} />}
        {checkForSlug(
          item.categories.nodes,
          localeUrls[localeID]["STORES_E"]
        ) && <img src={badgesLocale[localeID]["cuis"]} />}
        {checkForSlug(
          item.categories.nodes,
          localeUrls[localeID]["STORES_G"]
        ) && <img src={badgesLocale[localeID]["groc"]} />}
        {checkForSlug(
          item.categories.nodes,
          localeUrls[localeID]["STORES_C"]
        ) && <img src={badgesLocale[localeID]["bars"]} />}
        {!!item.tags.nodes.length && (
          <div className="tagsCon">
            {!!item.tags.nodes.length &&
              item.tags.nodes.map((tag, indexT) => {
                return (
                  <div key={indexT} className="tag">
                    {!!flagsIcons?.[tag.slug] ? (
                      <img src={flagsIcons[tag.slug]} />
                    ) : (
                      ""
                    )}
                    <span>{tag.name}</span>
                  </div>
                )
              })}
          </div>
        )}
      </div>
    </div>
  )
}

export const FarmerCard = ({ item, localeID }) => {
  return (
    <div className="farmerCard">
      <div className="logoCirCon">
        <GatsbyImage
          image={
            item?.featuredImage?.node?.localFile?.childrenImageSharp[0]
              ?.gatsbyImageData
          }
          alt={item?.featuredImage?.node?.altText ?? "Predajca"}
        />
      </div>
      <h3>{item.title}</h3>
      <p className="sub">{item.acfFarmersData.subtitle}</p>
      <p className="center">
        <img src={clockI} />
        <span>{labels[localeID]["OPENED"]}</span>
        <span className="oh">8:00 - 19:00</span>
      </p>
      <p className="oh">{labels[localeID]["GFLOOR"]}</p>
      <img src={badgesLocale[localeID]["mark"]} />
    </div>
  )
}

export default StoresSlider
